/*
	
	2023.05.25	-	UF dynSelectAdd(...) / dynSelectEdit(..)
	2022.11.18	-	add dynSelectClear(...)
	2022.11.17	-	add dynSelectReset(...)
	2022.03.23	-	update (unknown)
	2021.12.21	-	Init
	
	//
	
	Миксин для построения связанных динамических списков на основе древовидной структуры:

		[
			{
				title: 'SSD',
				value: 1,
				//
				childs: [
					{
						title: 'Brand',
						value: 11,
						//
						childs: [
							{
								title: 'Ultra FAST',
								value: 111,
							},
						],
					},
				],
			},
			{
				title: 'HDD',
				value: 2,
				//
				childs: [
					{
						title: 'Some brand',
						value: 21,
					},
					{
						title: 'Another brand',
						value: 22,
					},
				],
			},
		]

*/
/*

import DynSelect from '@/mixins/dynSelect'

//

export default {

	//

	mixins: [
		DynSelect,
	],

	//

	data: {
		
		// ...
		
				selectors: {

					items: [],

					buffer:	[],

				},

				select: {

					one:	null,
					two:	null,
					
					...

				},

		// ...
		
	}

}

*/
/*
	USAGE:

		1
		
		<v-select
			:items="dynSelectItems(0, selectors.buffer, selectors.items)"
			item-text="title"
			item-value="value"
			v-model="select.one"						<!--	ONE	-->
			@change="dynSelectReset(select, 'one')"		<!--	ONE	-->
			<!-- return-object -->
		/>
		
		2+

		<v-select
			:items="dynSelectItems(1, selectors.buffer, selectors.items, select.one)"	<!--	ONE	-->
			item-text="title"
			item-value="value"
			v-model="select.two"						<!--	TWO	-->
			@change="dynSelectReset(select, 'two')"		<!--	TWO	-->
			<!-- return-object -->
		/>

*/
export default {

	methods: {

		/*
			Очитка значения селектора для отправки формы (удаление блока потомков)
		*/
		dynSelectClear(value, _text = 'title', _value = 'value') {

			if(typeof(value) === 'object') {

				if(value) {

					return {
						[_text]:	value[_text],
						[_value]:	value[_value],
					}
				
				}
				
				return null

			}
			
			return value
		
		},

		/*
			Очистка дочерних селекторов по индексу текущего
		*/
		dynSelectReset(selected, key) {

			let erase = false

			for(let current in selected) {

				if(erase) {
					selected[current] = null
				}

				if(current == key) {
					erase = true
				}

			}
			
		},

		/*
			Выдача массива для построения динамического (связанного) списка по заданным параметрам
		*/
		dynSelectItems(level, buffer, items, parent, _value = 'value', _childs = 'childs') {
			
			// Если уровень не нулевой, приступаем к извлечению нужного узла из дерева
			if(level) {

				let branch = []

				// Получаем предыдущий элемент из буфера
				let previous = buffer[(level - 1)]

				// Ищем в нем текущий элемент по parent
				if(parent) {

					// Если в селекторе используется RETURN-OBJECT, то получам ТОЛЬКО значение объекта
					let value = (typeof(parent) === 'object') ? parent[_value] : parent

					for(let i in previous) {

						// Проверяем на совпадение и заголовок и значение элемента
						if(
							previous[i][_value] == value
						) {

							//
							if(previous[i][_childs]) {
							
								branch = previous[i][_childs]

								// Сохраняем ветку в буфер под текущим номером уровня
								buffer[level] = branch

							}

						}

					}

				}

				return branch

			}

			// Если уровень нулевой, то возвращаем все дерево
			else {

				buffer[0] = items

				return items

			}

		},

		/*
			Проверка значения селектора на пустоту (удобно использовать для подстановки классов CSS : { 'mt-8': !dynSelectNotSet( %selector% ) } )
		*/
		dynSelectNotSet(obj) {

			if(obj == null) {
				return true
			}

			return Object.keys(obj).length === 0

		},

		/*
			Склонение числительных в селекторе по переданному значению
		*/
		diclentionSelectItems(number, items, delimiter = '|') {
			
			let results = []
			
			for(let key in items) {
				
				if(typeof items[key] == 'object') {
					
					if(items[key].title) {
					
						results.push(Object.assign(
							//
							{},
							//
							items[key],
							//
							{
								title: this.diclentionNumber(
									number,
									items[key].title.split(delimiter),
									false
								),
							}
						))

					}
					
				}
				
				else {
					
					results.push(this.diclentionNumber(
						number,
						items[key].title.split(delimiter),
						false
					))
					
				}
				
			}
			
			return results
			
		},
		
		/*
			Склонение числительных
//[fix] либо вынести во вне, либо интегрировать в ф-цию выше
			(5, ['секунда', 'секунды', 'секунд'], true/false)
		*/
		diclentionNumber(number, titles, full) {
			
			let cases = [2, 0, 1, 1, 1, 2];  

			let word = titles[ (number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5] ];
			
			return ((full) ? number +' ' : '') + word;

		},

		/*
		*/
		dynSelectAdd(level, buffer, items, parent, _value = 'value', _childs = 'childs') {
			
		},
		
		/*
		*/
		dynSelectEdit(level, buffer, items, parent, _value = 'value', _childs = 'childs') {
			
		}

	}

}