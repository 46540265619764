<template>

	<div>

		<v-dialog
			persistent
			scrollable
			v-model="show"
			width="600px"
		>

			<v-card>

				<v-toolbar>

					<v-toolbar-title>Передача канала</v-toolbar-title>

					<v-progress-linear
						:active="loading"
						absolute
						bottom
						indeterminate
					/>

					<v-spacer />

					<v-tooltip bottom>
						
						<template v-slot:activator="{ on, attrs }">
						
							<v-btn
								icon
								v-on="on"
								v-bind="attrs"
								@click="show = false"
							>
								<v-icon>mdi-close</v-icon>
							</v-btn>

						</template>
						
						<span>Закрыть</span>

					</v-tooltip>

				</v-toolbar>

				<v-card-text>

					<v-container>

						<v-form
							ref="form"
							v-model="valid"
						>

							<v-row>

								<v-col cols="12">

									<v-select
										label="Пользователь"
										:items="dynSelectItems(0, selectors.buffer, selectors.items)"
										item-text="title"
										item-value="value"
										v-model="select.user"
										@change="dynSelectReset(select, 'user')"
										required
									/>

								</v-col>

							</v-row>

							<v-row>

								<v-col cols="12">

									<v-select
										label="Список"
										:items="dynSelectItems(1, selectors.buffer, selectors.items, select.user)"
										item-text="title"
										item-value="value"
										v-model="select.category"
										@change="dynSelectReset(select, 'category')"
										required
									/>

								</v-col>

							</v-row>

						</v-form>

					</v-container>

				</v-card-text>

				<v-card-actions class="pb-4">

					<v-spacer/>

					<v-btn
						:disabled="!valid || loading"
						@click="transfer()"
						color="primary"
					>Применить</v-btn>

				</v-card-actions>

			</v-card>

		</v-dialog>

		<!-- -->
		<g-notify
			:message="notify.message"
			:timeout="notify.timeout"
			:type="notify.type"
			v-model="notify.show"
		/>

	</div>

</template>

<script>

import Debug			from '@/mixins/Debug'
import NetworkExchange	from '@/mixins/networkExchange'
import LocalStorage		from '@/mixins/localStorage'
import DynSelect		from '@/mixins/dynSelect'

export default {
	
	name: 'DialogControlTransfer',

	//
	
	props: {

		channel: {
			type: String,
			required: true,
		},

		//

		value: {
			type: Boolean,
			required: false,
		},

	},

	//

	mixins: [
		Debug,
		NetworkExchange,
		LocalStorage,
		DynSelect,
	],

	//
	
	data: () => ({

		notify: {
			show:		false,
			type:		'notice',
			message:	'',
			timeout:	2500,
		},

		//
		
		loading: false,
		
		valid: false,

		//

		selectors: {

			items: [],

			buffer:	[],

		},

		select: {

			user:		null,
			category:	null,

		},

	}),
	
	//
	
	computed: {
		
		show: {
		
			get() {
				return this.value
			},
			
			set(value) {
				this.$emit('input', value)
			}
		
		}
	},
	
	//
	
	watch: {

		value: function() {

			if(this.value) {

// RECEIVE DATA HERE (on open)
this._debug('OPENED')

				this.load()

			}

			else {

// CLEAR DATA HERE (on close)
this._debug('CLOSED')

			}

		}

	},

	//
	
	methods: {

		load() {

			this.loading = true
			
			//

			this.nwGet(
				{
					method:		'channel.transfer',
					//
					random:		new Date().getTime(),
					auth:		this.lsGet('auth', null),
				},
				(data) => {
					
					this.selectors.buffer = []

					this.selectors.items = data

				},
				(state) => {
					this.loading = state
				},
			)
		
		},
		
		//

		transfer() {

			if(window.confirm('Точно передать канал выбранному пользователю?')) {

				this.loading = true
				
				//

				this.nwPut(
					{
						method:		'channel.transfer',
						//
						user:		this.select.user,
						channel:	this.channel,
						category:	this.select.category,
						//
						auth:		this.lsGet('auth', null),
					},
					(data) => {

						this.show = false

					},
					(state) => {
						this.loading = state
					},
				)

			}
		
		},

	},
	
}

</script>

<style>

</style>