<template>

	<div>

<!--

	https://i.ytimg.com/vi/F1rKhW-UAfM/hq720.jpg

1280*720

	320*180
	640*360
	960*540
	1280*720

-->

<!--
	uid	
		video		"73482256A7CBA304CAA3E153A5DDD0DB"
		channel		"C6F67FA261C284E2F8A2B1870890D236"
		category	"9CFA7AEFCC61936B70AAEC6729329EDA"
	ident	
		video		"lDcOaMxo-7c"
		channel		"UC1CchA0SjApw4T-AYkN7ytg"
	title		"ИСТОРИЯ БОЛЬШОГО ПРОВАЛА: ANTHEM"
	thumbnail	"https://i.ytimg.com/vi/lDcOaMxo-7c/mqdefault.jpg"
	duration	"25:51"
	is360		0
	created	
		date		"2023.07.30"
		time		"20:30:58"
	author		"IGM"
	new			false
	lists		[]
	external	0
	important	0
	viewed		0
	favourite	0
-->

		<v-card
			class="content-bg card mx-auto"
			:class="{ 'dimmed': video.viewed, }"
			color="transparent"
			width="320"
			flat
			tile
		>

			<v-img
				height="180"
				:src="thumbnail.current"
				class="caption align-end hand frame"
				:class="{ 'new': video.new, 'highlight': highlight }"
				@click="emitPutOpenPlayer()"
				@contextmenu="menuVideoShow"
				@mouseover="hover(0.5, video.ident.video)"
				@mouseleave="hover(1)"
			>

				<v-row
					class="pl-1 pr-1 pb-1"
					:style="{opacity: (video.favourite ? 1 : (opacity == 1 ? 0 : 1))}"
				>

					<v-col cols="6"></v-col>

					<v-col cols="6" class="text-right">

						<span
							class="substrate pt-1 pr-1 pb-1 pl-1 rounded"
						>
							<v-icon
								small
								:color="video.favourite ? 'red' : ''"
								@click.stop="toggleFavourite(video.ident.video)"
							>mdi-heart</v-icon>
						</span>

					</v-col>

				</v-row>

				<v-row>
					<v-col
						cols="12"
						class="ma-10 pt-4"
					>
						&nbsp;
					</v-col>
				</v-row>

				<v-row
					no-gutters
					class="pl-1 pr-1 pb-1"
					:style="{opacity: opacity}"
				>

					<v-col cols="6" class="text-left">

						<span
							class="substrate pt-1 pr-1 pb-1 pl-1 rounded"
							:title="video.created.time"
						>
							{{ video.created.date }}
						</span>

						<template v-if="video.is360">

							<span
								class="substrate pt-1 pr-1 pb-1 pl-1 ml-1 rounded red darken-4"
							>
								360
							</span>

						</template>

					</v-col>

					<v-col cols="6" class="text-right">

						<span
							class="substrate pt-1 pr-1 pb-1 pl-1 rounded"
						>
							{{ video.duration }}
						</span>
						
					</v-col>

				</v-row>

				<v-progress-linear
					v-if="video.progress > 0.0"
					class="pl-1 pr-1 pb-1"
					:value="video.progress"
					:style="{opacity: opacity}"
				/>

			</v-img>
			
			<!--	-->
			
			<v-row no-gutters>

				<v-col cols="12">

					<template v-if="video.external">

						<v-card-text
							class="text--secondary pt-4 pr-0 pb-0 pl-0 text-truncate"
						>
							{{ video.author }}
						</v-card-text>

					</template>

					<template v-else>

						<v-card-text
							class="text--secondary pt-4 pr-0 pb-0 pl-0 text-truncate hand underline"
							@click="emitPutOpenChannel(video.uid.category, video.uid.channel)"
							@contextmenu="menuAuthorShow"
						>
							<span
								:class="{ 'important underline': video.important, }"
							>
								{{ video.author }}
							</span>
						</v-card-text>

					</template>

				</v-col>
				
				<v-col cols="12">

					<v-card-text
						class="pt-0 pr-0 pb-2 pl-0"
						:class="{ 'text-truncate': truncated, }"
						:title="video.title"
					>
						<span
							:class="{ 'important': video.important, }"
						>
							{{ video.title }}
						</span>
					</v-card-text>

				</v-col>

			</v-row>

			<!-- AUTHOR MENU -->
			<v-menu
				absolute
				offset-y
				v-model="menu.author.show"
				:position-x="menu.author.x"
				:position-y="menu.author.y"
			>

				<v-list>

					<v-list-item
						link
						dense
						@click="emitPutEditChannel(video.uid.channel)"
					>
<!--
						<v-list-item-icon>
							<v-icon>mdi-trending-up</v-icon>
						</v-list-item-icon>
-->
						<v-list-item-content>
							<v-list-item-title>Редактировать</v-list-item-title>
						</v-list-item-content>
					</v-list-item>

				</v-list>

			</v-menu>

			<!-- VIDEO MENU -->
			<v-menu
				v-model="menu.video.show"
				:position-x="menu.video.x"
				:position-y="menu.video.y"
				absolute
				offset-y
				link
			>

				<v-list>

					<v-list-item link dense @click="toggleArchive(video.ident.video)">
						<v-list-item-icon>
							<v-icon
								:style="{ color: video.viewed ? 'orange' : '' }"
							>{{ video.viewed ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}</v-icon>
						</v-list-item-icon>
						<v-list-item-title>
							{{ video.viewed ? 'В архиве' : 'В архив' }}
						</v-list-item-title>
					</v-list-item>

					<v-divider/>

					<v-list-item link dense @click="openWindow('chanl', video.ident.channel)">
						<v-list-item-icon>
							<v-icon>mdi-open-in-new</v-icon>
						</v-list-item-icon>
						<v-list-item-title>
							Канал на Youtube
						</v-list-item-title>
					</v-list-item>

					<v-list-item link dense @click="openWindow('ytube', video.ident.video)">
						<v-list-item-icon>
							<v-icon>mdi-open-in-new</v-icon>
						</v-list-item-icon>
						<v-list-item-title>
							Видео на YouTube
						</v-list-item-title>
					</v-list-item>

					<v-list-item link dense @click="openWindow('embed', video.ident.video)">
						<v-list-item-icon>
							<v-icon>mdi-open-in-new</v-icon>
						</v-list-item-icon>
						<v-list-item-title>
							Видео в новом окне
						</v-list-item-title>
					</v-list-item>

					<v-divider/>

					<v-list-item link dense @click="copyToClipboard('https://www.youtube.com/watch?v='+ video.ident.video)">
						<v-list-item-icon>
							<v-icon>mdi-content-copy</v-icon>
						</v-list-item-icon>
						<v-list-item-title>
							Скопировать ссылку
						</v-list-item-title>
					</v-list-item>

					<template v-if="!video.external">

						<template
							style="max-height:80vh;"
							class="overflow-y-auto"
							v-if="lists.length > 1"
						>

							<v-divider/>

							<!--
								scrollable hack
								
								( https://stackoverflow.com/questions/54904746/scrolling-list-in-vuetify )
								( https://stackoverflow.com/questions/62586609/v-menu-unable-to-scroll-down-to-view-other-list )
							-->
							<div
								style="max-height:60vh;"
								class="overflow-y-auto"
							>

								<v-list-item
									link
									dense
									v-for="list in lists"
									:key="list.value"
									@click="toggleList(list.value)"
								>

									<v-list-item-icon
										v-if="video.lists && video.lists.includes(list.value)"
									>
										<v-icon>mdi-checkbox-marked</v-icon>
									</v-list-item-icon>

									<v-list-item-icon
										v-else
									>
										<v-icon>mdi-checkbox-blank-outline</v-icon>
									</v-list-item-icon>

									<!-- -->

									<v-list-item-title>
										{{ list.title }}
									</v-list-item-title>

								</v-list-item>
									
							</div>

						</template>

					</template>

				</v-list>

			</v-menu>

		</v-card>

		<!-- -->
		<g-notify
			:message="notify.message"
			:timeout="notify.timeout"
			:type="notify.type"
			v-model="notify.show"
		/>

	</div>

</template>

<script>

import NetworkExchange	from '@/mixins/networkExchange'
import LocalStorage		from '@/mixins/localStorage'

export default {
	
	name: 'VideoBlockMain',

	//
	
	props: {

		lists: {
			type: Array,
			required: true,
		},

		video: {
			type: Object,
			required: true,
		},

		truncated: {
			type: Boolean,
			required: true,
		},

	},

	//

	mixins: [
		NetworkExchange,
		LocalStorage,
	],

	//

	data: () => ({

		notify: {
			show:		false,
			type:		'notice',
			message:	'',
			timeout:	2500,
		},
		
		//

		menu: {

			author: {
				
				show: false,
				x: 0,
				y: 0,
				
			},

			video: {
				
				show: false,
				x: 0,
				y: 0,
				
			},
			
		},

		//

		thumbnail: {
			current:	'',
			animate:	'',
		},
		
		//
		
		opacity: 1,
		
		debouncer: 0,
		
	}),
	
	//

	computed: {

		highlight() {
			return (this.video.lists === null) ? false : (this.video.lists.length > 0)
		}

	},

	//
	
	methods: {

		hover(opacity, ident) {

			this.opacity = opacity
			
			//
			
			if(ident) {

				clearTimeout(this.debouncer)

				this.debouncer = setTimeout(() => {

					if(this.thumbnail.animate == '') {
						
						this.nwGet(
							{
								section:	null,
								method:		'thumbnail.animate',
								//
								ident:		ident,
								//
								random:		new Date().getTime(),
							},
							(data) => {

								if(data) {
									
									this.thumbnail.animate = data
									this.thumbnail.current = data
									
								}

							},
							(state) => {
								this.loading = state
							},
						)
					
					}
					
					else {
						this.thumbnail.current = this.thumbnail.animate
					}

				}, 1000)

			}
			
			else {
				
				clearTimeout(this.debouncer)
				
				this.restoreThumbnail()

			}

		},

		//
		
		restoreThumbnail() {
			this.thumbnail.current = this.video.thumbnail
		},
		
		//

		toggleFavourite(ident) {

			this.nwPut(
				{
					method:		'video.controls.favourite',
					//
					video:		ident,
					//
					auth:		this.lsGet('auth', null),
				},
				(data) => {

					//[!] переписать нахуй на основе SOCIALS ->src/components/block/post/block.vue (emit)
					this.video.favourite = !this.video.favourite

				},
				(state) => {
					this.loading = state
				},
			)
			
		},
		
		//
		
		toggleArchive(ident) {

			this.loading = true

			this.nwPut(
				{
					method:		'video.controls.viewed',
					//
					video:		ident,
					//
					auth:		this.lsGet('auth', null),
				},
				(data) => {

					//[!] переписать нахуй на основе SOCIALS ->src/components/block/post/block.vue (emit)
					this.video.viewed = !this.video.viewed

				},
				(state) => {
					this.loading = state
				},
			)

		},

		//

		menuAuthorShow(e) {
			
			e.preventDefault()
			
			this.menu.author.show = false
			
			this.menu.author.x = e.clientX
			
			this.menu.author.y = e.clientY
			
			this.$nextTick(() => {
				this.menu.author.show = true
			})
			
		},

		//
		
		menuVideoShow(e) {
			
			e.preventDefault()
			
			this.menu.video.show = false
			
			this.menu.video.x = e.clientX
			
			this.menu.video.y = e.clientY
			
			this.$nextTick(() => {
				this.menu.video.show = true
			})
			
		},

		//
		
		copyToClipboard(text) {

			let result = true

			if(navigator.clipboard && window.isSecureContext) {

				navigator.clipboard.writeText(text).then(function() {
					result = true
				}, function(err) {
					result = false
				});

			}
			
			else {

				let textArea = document.createElement('textarea')

				textArea.value = text

				textArea.style.position = 'fixed'
				textArea.style.left = '-999999px'
				textArea.style.top = '-999999px'

				document.body.appendChild(textArea)

				textArea.focus()
				textArea.select()

				new Promise((res, rej) => {
					document.execCommand('copy') ? res() : rej()
					textArea.remove()
				}).then(function() {
					result = true
				}, function(err) {
					result = false
				})

			}

			if(result) {
				this.notify.color	=	'green accent-4'
				this.notify.message	=	'Скопировано в буфер обмена'
			}
			
			else {
				this.notify.color	=	'red accent-4'
				this.notify.message	=	'Не удалось копировать'
			}

			this.notify.timeout	=	1250
			this.notify.show	=	true

		},

		//

		toggleList(list) {

			this.loading = true

			this.nwPut(
				{
					method:		'video.controls.list',
					//
					list:		list,
					video:		this.video.ident.video,
					//
					auth:		this.lsGet('auth', null),
				},
				(data) => {

					this.video.lists = data

				},
				(state) => {
					this.loading = state
				},
			)

		},

		//
		
		openWindow(type, ident) {

			switch(type) {
				
				case 'embed':
					window.open('https://www.youtube.com/embed/'+ ident)
				break

				case 'ytube':
					window.open('https://www.youtube.com/watch?v='+ ident)
				break

				case 'chanl':
					window.open('https://www.youtube.com/channel/'+ ident +'/videos')
				break

			}

		},

		//
		
		emitPutOpenPlayer() {

			// Отправляем родителю сигнал с полезной нагрузкой
			// ( https://ru.vuejs.org/v2/api/#vm-emit )
			this.$emit('open-player', {
				external:	this.video.external,
				video:		this.video.ident.video,
			})

		},

		//
		
		emitPutOpenChannel(category, channel) {

			// Отправляем родителю сигнал с полезной нагрузкой
			// ( https://ru.vuejs.org/v2/api/#vm-emit )
			this.$emit('open-channel', {
				category:	category,
				channel:	channel,
			})

		},

		//
		
		emitPutEditChannel(channel) {

			// Отправляем родителю сигнал с полезной нагрузкой
			// ( https://ru.vuejs.org/v2/api/#vm-emit )
			this.$emit('edit-channel', {
				channel:	channel,
			})

		},

	},
	
	//
	
	mounted() {
		this.restoreThumbnail()
	}

}

</script>

<style>

.underline	{
	text-decoration: underline;
	text-decoration-skip-ink: none;
}
.hand {
	cursor: pointer;
}
.dimmed {
	filter: grayscale(100%);
	opacity: 0.3;
}
	.dimmed:hover {
		filter: grayscale(0%);
		opacity: 1;
	}

/* LIGHT */

.theme--light .frame			{		outline: 8px solid #ccc;					}
.theme--light .frame:hover		{		outline: 8px solid #999;					}

.theme--light .new				{		outline: 8px solid #1b5e20;					}
.theme--light .new:hover		{		outline: 8px solid #8daf90;					}

.theme--light .highlight		{		outline: 8px solid #b71c1c !important;		}
.theme--light .highlight:hover	{		outline: 8px solid #db8e8e !important;		}

.theme--light .substrate		{
	background: #ccc;
	color: #000;
}

.theme--light .important {
	color: #2196f3;
}
	.theme--light .important.underline {
		text-decoration: underline;
		text-decoration-skip-ink: none;
	}

/* DARK */

.theme--dark .frame				{		outline: 8px solid #212121;					}
.theme--dark .frame:hover		{		outline: 8px solid #383838;					}

.theme--dark .new				{		outline: 8px solid #1b5e20;					}
.theme--dark .new:hover			{		outline: 8px solid #8daf90;					}

.theme--dark .highlight			{		outline: 8px solid #b71c1c !important;		}
.theme--dark .highlight:hover	{		outline: 8px solid #db8e8e !important;		}

.theme--dark .substrate		{
	background: #212121;
	color: #fff;
}

.theme--dark .important	{
	color: #ff9800;
}
	.theme--dark .important.underline	{
		text-decoration: underline;
		text-decoration-skip-ink: none;
	}

</style>